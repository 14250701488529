<script>
import Embedo from 'embedo';
import ContentBlockView from '@shared/mixins/ContentBlockView';

if (!window.myEmbedo) {
  window.myEmbedo = new Embedo({
    facebook: {
      appId: '1695348747214375',
      version: 'v9.0',
      xfbml: true,
      access_token: '358a699cfa45f7ed638e22c3200923d9',
    },
    twitter: true,
    instagram: {
      access_token: '358a699cfa45f7ed638e22c3200923d9',
    },
    pinterest: true,
  });
}

export default {
  mixins: [ContentBlockView],
  mounted() {
    this.loadEmbed();
  },
  updated() {
    this.loadEmbed();
  },
  methods: {
    loadEmbed() {
      this.$refs.embed.innerHTML = '';
      window.myEmbedo.load(this.$refs.embed, this.data.url, { maxwidth: 640 });
    },
  },
};
</script>

<template>
  <div>
    <div class="comp_embed mw-full">
      <div ref="embed" class="comp_embed_child" />
    </div>
    <p v-if="data.caption" class="mt-3 has-text-centered is-size-8">
      {{ data.caption }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.comp {
  &_embed {
    display: flex;
    justify-content: center;
    text-align: center;

    &_child {
      flex: 0 0 640px;
      max-width: 640px;

      @include mobile {
        &,
        & ::v-deep * {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
